import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SEO from '../components/seo';
import './index.scss';
import ProductHeader from '../components/product-header';
import ArrowRightIcon from '!svg-react-loader!../images/vectors/arrow_right.svg';
import SALogo from '!svg-react-loader!../images/vectors/sa-logo.svg';
import SAFeedback from './sa-feedback';
import BookDemoPage from '../components/book-demo';
import { OfferingItemContainer } from '../components/extra-components';
import * as Constants from '../utils/constants';
import DetailedSpecs from '../components/detailed-specs';
import ComingSoon from '../components/coming-soon';
import { CTypes } from '../utils/constants';


const SmartAssistantLogo = () => {
  return (
    <SALogo width={25} height={28} alt={'smart assistant logo'} />
  );
};


const SABaseOfferingPage = () => {
  const viewDetailsClicked = (page) => {
    return (e) => {
      e.stopPropagation();
      console.log('view details clicked', page, e);
      return true;
    };
  };
  return (
    <>
      <div className='micro-app-offering-header'>
        <h4>Base Offering</h4>
        <p className='offering-pricing'>
          Includes our six most popular micro apps and MedTrail support. <br />
          Price: ₹18K + GST / doctor / year
        </p>
      </div>
      <OfferingItemContainer list={Constants.baseOfferingItemsList} fullWidth />
    </>
  );
};


const SmartAssistantUpgradesPage = () => {

  const viewDetailsClicked = (page) => {
    return (e) => {
      e.stopPropagation();
      console.log('view details clicked', page, e);
      return true;
    };
  };
  return (
    <div>
      <div className='micro-app-offering-header'>
        <h4>Upgrades</h4>
        <p className='offering-pricing'>
          Powerful micro apps to scale up your practice and provide end-to-end care to your patients. <br />
          Price: Pay-per-use
        </p>
      </div>
      <OfferingItemContainer list={Constants.upgradesContentList} fullWidth />
    </div>
  );
};

const SAClinicDetailedSpecsContent = () => {
  return (
    <div className='specs-details-container container'>
      <section>
        <h4>Smart Records</h4>
        <ul>
          <li>Intuitive EMR with contextual recommendation engine</li>
          <li>Digitize your hand-written prescriptions using our proprietary Smart Pad<sup>&reg;</sup> or Smart
            Scanner<sup>&reg;</sup> with zero
            habit change
          </li>
          <li>Access all health records in an intuitive format</li>
          <li>Be the guardian of your patients’ health data with 256-bit encryption & SSL security in data exchange and
            at-rest
          </li>
          <li>Achieve ABDM compliance</li>
          <li>Share contextual digital content with your patients automatically by writing a hashtag in your
            prescription
          </li>
          <li>Integrated with Smart Desk<sup>&reg;</sup> - Share prescriptions & follow-up reminders with your patients
            over WhatsApp
            automatically
          </li>
          <li>Integrated with Smart Analytics<sup>&reg;</sup> - Get insights around patient flow, their medication &
            diagnostics
          </li>
          <li>Integrated with MyHealthBook<sup>&reg;</sup> - Upload prescriptions to MyHealthBook automatically and help
            them manage
            their medication & records
          </li>
        </ul>
      </section>
      <section>
        <h4>Smart Appointments</h4>
        <ul>
          <li>Create your digital identity & enable your patients to discover you online</li>
          <li>Manage online & offline appointments of your patients</li>
          <li>Collect payment for your consultations via all popular payment options - Netbanking, UPI, Wallet, Card,
            EMI, Pay Later, Pay at Clinic
          </li>
          <li>Integrated with MyHealthBook<sup>&reg;</sup> - Deliver teleconsultation via our real-time engagement
            platform
          </li>
          <li>Integrated with Smart Records<sup>&reg;</sup> - Get easy access to patient’s past health records & share
            prescriptions
            during consultation
          </li>
          <li>Integrated with Smart Account<sup>&reg;</sup> - Get a summary of your earnings through consultations.</li>
          <li>Integrated with Smart Rep<sup>&reg;</sup> - Reach out to any pharma at your convenience & manage in-bound
            and out-bound
            MR appointments.
          </li>
          <li>Integrated with Smart Analytics<sup>&reg;</sup> - Track source of your patient & MR appointments</li>
          <li>Integrated with Smart Desk<sup>&reg;</sup> - Avoid delay & no-show with timely notifications over
            WhatsApp.
          </li>
          <li>Integrated with Smart Campaigns<sup>&reg;</sup> - Help your patients discover you via Google, WhatsApp,
            Facebook and
            SMS
          </li>
        </ul>
      </section>

      <section>
        <h4>Smart Campaigns</h4>
        <ul>
          <li>Run awareness campaigns via WhatsApp and SMS for your existing patient pool</li>
          <li>Reach out to wider audience via Google & Facebook to serve new patients</li>
          <li>Reduce expense on digital campaigns with our proprietary algorithms & demographic filters</li>
          <li>Discover community-sourced content templates relevant to your practice</li>
          <li>Integrated with Smart Analytics<sup>&reg;</sup> - Know efficacy of your awareness campaigns on Google,
            Facebook,
            WhatsApp, SMS & MyHealthBook<sup>&reg;</sup>
          </li>
          <li>Integrated with Smart Desk<sup>&reg;</sup></li>
        </ul>
        <p>
          <b>Note:</b> We believe your patient pool is a reflection of your skill, hard work, credibility and ethics.
          Our
          product strictly restricts cross-targeting of patients.
        </p>
      </section>

      <section>
        <h4>MedTrail Support</h4>
        <ul>
          <li>Get a dedicated account owner - your human assistant with qualification from a top Indian business
            school
          </li>
          <li>Get a dedicated account manager for 24x7 support</li>
          <li>Connect over call or chat at your convenience</li>
          <li>Get digitised copy of all interactions with us so that we deliver what we promise</li>
        </ul>
      </section>


    </div>
  );
};


const SAClinicsDetailsPage = ({ location, navigate }) => {

  // useEffect(() => {
  //   window.scrollTo({ behavior: 'auto', top: 0 });
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('running timeout');
  //     window.scroll(0, 0);
  //   }, 100);
  // }, []);

  const variants = {
    hidden: (custom) => {
      return {
        opacity: 0,
        y: 0
      };
    },
    visible: (custom) => ({
      opacity: 1,
      y: 0
    })
  };

  return (
    <div className='mt-page-root container'>
      <SEO title="Smart Assistant - Base Offering" />
      <div className='page-fixed-header container'>
        <ProductHeader
          title={'Smart Assistant'}
          productImage={SmartAssistantLogo}
          backLinkTo={`/#home-SA`}
          featureText={`for Clinics`}
          cType={CTypes.SA_CLINICS}
        />
      </div>
      <motion.div
        className="container-fluid main-container-full sa-page-scroller"
        initial={'hidden'}
        animate={'visible'}
        variants={{
          visible: {
            transition: {
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05
            }
          },
          hidden: {
            transition: {
              duration: 0.3
            }
          }
        }}
      >
        <motion.section variants={variants}>
          <div className='sa-base-description'>
            Modular digital assistant that is designed as an operating system for clinics. Its powerful micro apps
            digitise doctor interactions with all stakeholders, automate workflows to generate higher operating income
            and
            optimise for end-to-end care of patients with minimal operational hassle.
          </div>
        </motion.section>

        <div className='row mt-offering-section-container'>
          <motion.section className='row' variants={variants}>
            <SABaseOfferingPage />
          </motion.section>

          <motion.section className='row' variants={variants}>
            <SmartAssistantUpgradesPage />
          </motion.section>
        </div>

        {/*<div className='row'>*/}
        {/*  <div className='col-lg-4' />*/}
        {/*  <motion.section className='col-lg-4' variants={variants}>*/}
        {/*    <DetailedSpecs>*/}
        {/*      <SAClinicDetailedSpecsContent />*/}
        {/*    </DetailedSpecs>*/}
        {/*  </motion.section>*/}
        {/*</div>*/}

        <motion.section variants={variants}>
          <div className='empty-space' />
          <SAFeedback />
        </motion.section>

        {/*<motion.div className='desktop' variants={variants}>*/}
        {/*  <div className='empty-space' style={{ paddingBottom: 150 }} />*/}
        {/*  <div className='page-fixed-footer'>*/}
        {/*    <div className='row container'>*/}
        {/*      <div className='col-lg-12'>*/}
        {/*        <DetailedSpecs>*/}
        {/*          <SAClinicDetailedSpecsContent />*/}
        {/*        </DetailedSpecs>*/}
        {/*      </div>*/}
        {/*      <div className='col-lg-12' style={{ display: 'flex', alignItems: 'center' }}>*/}
        {/*        <BookDemoPage location={location} navigate={navigate} dynamic />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</motion.div>*/}

        {/*<motion.div className='desktop' variants={variants}>*/}
        {/*  <div className='empty-space' style={{ paddingBottom: 150 }} />*/}
        {/*  <div className='page-fixed-footer-horizontal'>*/}
        {/*    <div className='row container'>*/}
        {/*      <div className='col-lg-12'>*/}
        {/*        <DetailedSpecs>*/}
        {/*          <SAClinicDetailedSpecsContent />*/}
        {/*        </DetailedSpecs>*/}
        {/*      </div>*/}
        {/*      <div className='col-lg-12' style={{ display: 'flex', alignItems: 'center' }}>*/}
        {/*        <BookDemoPage location={location} navigate={navigate} dynamic />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</motion.div>*/}

      </motion.div>

      <div className='mobile'>
        <BookDemoPage cType={CTypes.SA_CLINICS} location={location} navigate={navigate} />
      </div>


    </div>
  );
};

export default SAClinicsDetailsPage;
