import React, { useState } from 'react';
import { navigate } from 'gatsby';
import './index.scss';
import Video from '../components/video';
import InfiniteScroll from 'react-infinite-scroll-component';
import ComingSoon from '../components/coming-soon';

const videoElem = (<Video
  videoSrcURL={`https://www.youtube.com/embed/Lv59h1zDl-U?start=120`}
  videoTitle={`Some Random Title`}
/>);

const FeedbackCard = ({ item }) => {
  const { doctorName, doctorCredentials, mostUsedMicroapps, ratings } = item;
  const ratingsObj = ratings.reduce((acc, x) => {
    acc[x.feature] = x;
    return acc;
  }, { overall: {} });
  const overallRating = ratingsObj.overall;

  const fcItemClicked = () => {
    console.log('fc item clicked', item.doctorName);
    navigate(`/view-feedback?id=${item.feedbackId}`);
  };

  return (
    <div
      className='feedback-card-container col-md-6 col-lg-6 col-xl-4 col-xxl-4'
      role='button'
      onClick={fcItemClicked}
      onKeyDown={fcItemClicked}
      tabIndex={-1}
    >
      <div className='row'>
        <div className='col-4 fc-thumbnail' />
        <div className='col-8 fc-content-container'>
          <h4>{doctorName}</h4>
          <p className='fc-subtext'>{doctorCredentials}</p>
          <div className='fc-fav-microapps'>
            <h4 className='fc-fav-title'>Most used micro apps:</h4>
            <p>
              {/*{mostUsedMicroapps.map(x => <span>#{x} &nbsp;</span>)}*/}
              {mostUsedMicroapps.map(x => `#${x}`).join(' ')}
            </p>
          </div>
          <div className='fc-rating'>
            <h4 className='fc-rating-title'>Feedback: <span>{overallRating.rating}/{overallRating.ratingMax}</span></h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const data = {
  feedback: [
    {
      feedbackId: 101,
      doctorName: 'Dr. Mohit Mittal',
      doctorCredentials: 'M.B.B.S, M.D (Dermatology)',
      mostUsedMicroapps: [ 'smart-records', 'smart-appointments' ],
      ratings: [
        {
          feature: 'overall',
          rating: 4,
          ratingMax: 5
        },
        {
          feature: 'smart-records',
          rating: 4,
          ratingMax: 5
        }
      ]
    },
    {
      feedbackId: 1001,
      doctorName: 'Dr. Mohit Mittal',
      doctorCredentials: 'M.B.B.S, M.D (Dermatology)',
      mostUsedMicroapps: [ 'smart-records', 'smart-appointments' ],
      ratings: [
        {
          feature: 'overall',
          rating: 4,
          ratingMax: 5
        },
        {
          feature: 'smart-records',
          rating: 4,
          ratingMax: 5
        }
      ]
    },
    {
      feedbackId: 1002,
      doctorName: 'Dr. Mohit Mittal',
      doctorCredentials: 'M.B.B.S, M.D (Dermatology)',
      mostUsedMicroapps: [ 'smart-records', 'smart-appointments' ],
      ratings: [
        {
          feature: 'overall',
          rating: 4,
          ratingMax: 5
        },
        {
          feature: 'smart-records',
          rating: 4,
          ratingMax: 5
        }
      ]
    },
    // {
    //   feedbackId: 1003,
    //   doctorName: 'Dr. Mohit Mittal',
    //   doctorCredentials: 'M.B.B.S, M.D (Dermatology)',
    //   mostUsedMicroapps: [ 'smart-records', 'smart-appointments' ],
    //   ratings: [
    //     {
    //       feature: 'overall',
    //       rating: 4,
    //       ratingMax: 5
    //     },
    //     {
    //       feature: 'smart-records',
    //       rating: 4,
    //       ratingMax: 5
    //     }
    //   ]
    // },
    // {
    //   feedbackId: 102,
    //   doctorName: 'Dr. Mohit Mittal',
    //   doctorCredentials: 'M.B.B.S, M.D (Neurology)',
    //   mostUsedMicroapps: [],
    //   ratings: [
    //     {
    //       feature: 'overall',
    //       rating: 3,
    //       ratingMax: 5
    //     },
    //     {
    //       feature: 'smart-records',
    //       rating: 3,
    //       ratingMax: 5
    //     }
    //   ]
    // }
  ]
};


const style = {
  height: 30,
  border: '1px solid green',
  margin: 6,
  padding: 8
};

const SAFeedback = () => {

  const [ items, setItems ] = useState(Array.from({ length: 20 }));
  const [ hasMore, setHasMore ] = useState(true);

  const feedbackList = data.feedback;

  const fetchMoreData = () => {
    if (items.length >= 500) {
      setHasMore(false);
      return;
    }
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      setItems(items.concat(Array.from({ length: 20 })));
    }, 500);

    fetch('https://jsonplaceholder.typicode.com/todos/1')
      .then(response => response.json())
      .then(json => console.log(json));

  };

  return (
    <div className='sa-feedback-root'>
      <h4 className='sa-feedback-main-title'>Unfiltered feedback from doctors</h4>
      <div className='sa-feedback-container container'>
        {/*<div className='sa-feedback-filters'>*/}
        {/*  <select className='form-select' defaultValue={'0'}>*/}
        {/*    <option value="0">Most Recent</option>*/}
        {/*    <option value="1">Filter Two</option>*/}
        {/*    <option value="2">Filter Three</option>*/}
        {/*    <option value="3">Filter Four</option>*/}
        {/*  </select>*/}
        {/*</div>*/}

        {/*<ComingSoon autoHeight />*/}

        <InfiniteScroll
          className='sa-feedback-infinite-scroll-container row gx-5'
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          height={'auto'}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {feedbackList.map((item, index) => (
            <FeedbackCard key={index} item={item} />
          ))}
        </InfiniteScroll>
        <div style={{ height: 10 }} />
      </div>
    </div>
  );
};

export default SAFeedback;
